<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
         Manage Storage
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="storageOverViewDetail.topCount > 0">
          <div class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center">
            <div class="flex items-center justify-between py-3 pl-2" style="display: flex;justify-content: center;">
              <p v-if="storageOverViewDetail.topCount > 0 && storageOverViewDetail.otherCount === 0" class="text-gray4 heading-5 pa-4" style="font-weight: bold;">Files Uploaded: {{storageOverViewDetail.topSize | getFileSize}}
              </p>
              <p v-if="storageOverViewDetail.otherCount > 0" class="text-gray4 heading-5 pa-4" style="font-weight: bold;">Top 100 Files ({{storageOverViewDetail.topSize | getFileSize}})
              </p>
            </div>
            <div class="flex gap-4" style="flex-wrap: wrap;">
              <div class="card rounded-xl border-t border-gray-100 bg-white attachment-card cursor-pointer group pb-4" style="width: 120px; height: 90px"  v-for="(attach, a) in topAttachmentsList" :key="a" @click="showFullImage(attach)">
                  <div v-if="attach.docType === 'image'" class="flex justify-center items-center w-full">
                      <img @error="handleImageError" style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                      <div class="invisible group-hover:visible absolute bg-gray4 w-full min-w-max card rounded-md p-1.5 px-3 text-white opacity-70 zIndex heading-4">{{ attach.displayFileName }}</div>
                      <div class="" style="position: absolute;display: flex;top: 72px;">
                        <p class="p-0 m-0 text-gray4 heading-7">{{attach.fileSize | getFileSize}}</p>
                      </div>
                  </div>
                  <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                      <img @error="handleImageError" style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                      <img class="flex items-center  text-4xl absolute text-white" height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                      <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                      <div class="" style="position: absolute;display: flex;top: 72px;">
                        <p class="p-0 m-0 text-gray4 heading-7">{{attach.fileSize | getFileSize}}</p>
                      </div>
                  </div>
                  <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                      <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                      <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                      <div class="" style="position: absolute;display: flex;top: 72px;">
                        <p class="p-0 m-0 text-gray4 heading-7">{{attach.fileSize | getFileSize}}</p>
                      </div>
                  </div>
                  <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                      <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                      <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                      <div class="" style="position: absolute;display: flex;top: 72px;">
                        <p class="p-0 m-0 text-gray4 heading-7">{{attach.fileSize | getFileSize}}</p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="storageOverViewDetail.otherCount > 0">
          <div class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center">
             <div class="flex items-center justify-between py-3 pl-2" style="display: flex;justify-content: center;">
              <p class="text-gray4 heading-5" style="font-weight: bold;">Other files, Showing 1 - {{otherAttachmentsList.length}} of {{storageOverViewDetail.otherCount}}: ({{storageOverViewDetail.otherSize | getFileSize}})
              </p>
            </div>
            <div class="flex gap-4" style="flex-wrap: wrap;" :style="otherAttachmentsList.length >= 100  ? `height: 650px;overflow-y: auto;overflow-x: hidden` : ``" id="otherAttachments" ref="otherAttachmentsContainer" @scroll="handleScroll">
              <div class=" card rounded-xl border-t border-gray-100 bg-white attachment-card cursor-pointer group pb-4" style="width: 120px; height: 90px"  v-for="(attach, a) in otherAttachmentsList" :key="a" @click="showFullImage(attach)">
                  <div v-if="attach.docType === 'image'" class="flex justify-center items-center w-full">
                      <img @error="handleImageError" style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                      <div class="invisible group-hover:visible absolute bg-gray4 w-full min-w-max card rounded-md p-1.5 px-3 text-white opacity-70 zIndex heading-4">{{ attach.displayFileName }}</div>
                      <div class="" style="position: absolute;display: flex;top: 72px;">
                        <p class="p-0 m-0 text-gray4 heading-7">{{attach.fileSize | getFileSize}}</p>
                      </div>
                  </div>
                  <div v-if="attach.docType === 'video'" class="flex justify-center items-center w-full">
                      <img @error="handleImageError" style="height: 60px;width: 120px;object-fit: contain;" :src="`${pathPrefix}${attach.thumbPath}/image200x200.jpg`" class="scroll-img-view">
                      <img class="flex items-center  text-4xl absolute text-white" height="35px" width="35px" src="@/assets/images/play.svg" alt="">
                      <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                      <div class="" style="position: absolute;display: flex;top: 72px;">
                        <p class="p-0 m-0 text-gray4 heading-7">{{attach.fileSize | getFileSize}}</p>
                      </div>
                  </div>
                  <div v-if="attach.docType === 'pdf'" class="flex justify-center items-center w-full">
                      <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file-pdf"></i></div>
                      <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                      <div class="" style="position: absolute;display: flex;top: 72px;">
                        <p class="p-0 m-0 text-gray4 heading-7">{{attach.fileSize | getFileSize}}</p>
                      </div>
                  </div>
                  <div v-if="attach.docType !== 'image' && attach.docType !== 'video' && attach.docType !== 'pdf'" class="flex justify-center items-center w-full">
                      <div style="" class="flex items-center text-primary text-4xl"><i class="fa-regular fa-file"></i></div>
                      <div class="invisible group-hover:visible w-full min-w-max -bottom-7 absolute bg-gray4 card rounded-md p-1.5 px-3 text-white opacity-70 zIndex">{{ attach.displayFileName }}</div>
                      <div class="" style="position: absolute;display: flex;top: 72px;">
                        <p class="p-0 m-0 text-gray4 heading-7">{{attach.fileSize | getFileSize}}</p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="storageOverViewDetail.otherCount === 0 && storageOverViewDetail.topCount === 0">
          <NoRecords :alertMessage="`No records found.`" />
        </div>
        <div v-if="shoFullImage">
          <AttachmentPreview :currentlyLoadAllAttachments="allAttachmentsArray" :pathPrefix="pathPrefix" :selectedData="selectedData"/>
        </div>
      </div>
    </div>
   </div>
</template>
<script>
import NoRecords from '@/View/components/noRecords.vue'
import AttachmentPreview from './attachmentPreview.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  components: {
    NoRecords,
    AttachmentPreview,
  },
  data() {
    return {
      loadingMoreData: false,
      storageOverViewDetail: {},
      topAttachmentsList: [],
      otherAttachmentsList: [],
      pathPrefix: '',
      topTotalAttachmentCount: 0,
      otherTotalAttachmentCount: 0,
      otherStart:100,
      otherLength:100,
      shoFullImage: false,
      allAttachmentsArray: [],
      selectedData: {}
    };
  },
  mounted() {
    this.getManageStorageOverviewDetail()
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    this.topGetAllAttachment()
    this.otherGetAllAttachment()
    document.body.style = 'overflow: visible;'
    this.$root.$on('fullImageHandler', (data) => {
      if (data) {
        console.log(data)
      }
      this.shoFullImage = false
    })
  },
  methods: {
  handleScroll() {
        const container = this.$refs.otherAttachmentsContainer;
        if (container && this.storageOverViewDetail.otherCount !== this.otherAttachmentsList.length) {
            const scrollHeight = container.scrollHeight;
            const scrollTop = container.scrollTop;
            const offsetHeight = container.offsetHeight;

            // Calculate a threshold value to determine when to trigger load more data
            const scrollBottomThreshold = 50; // Adjust this value as needed

            // Check if the user has scrolled near the bottom
            const isScrollNearBottom = scrollHeight - scrollTop <= offsetHeight + scrollBottomThreshold;

            if (isScrollNearBottom && !this.loadingMoreData) {
                // Set loading flag to prevent multiple rapid triggers
                this.loadingMoreData = true;

                // Use setTimeout to debounce the function call
                setTimeout(() => {
                    // User has scrolled near the bottom, load more data
                    this.otherAttachmentLoadMore().finally(() => {
                        // Reset loading flag after data loading completes
                        this.loadingMoreData = false;
                    });
                }, 500); // Adjust debounce time (in milliseconds) as needed
            }
        }
    },
    async otherAttachmentLoadMore() {
        // Save current scroll position
        const container = this.$refs.otherAttachmentsContainer;
        const currentScrollPosition = container.scrollTop;

        // Update otherStart for the next request
        this.$store.dispatch('SetLoader', { status: true, message: 'Loading...' });
        this.otherStart += 100;

        try {
            await this.otherGetAllAttachment(); // Assuming otherGetAllAttachment is an async function
        } catch (error) {
            // Handle error scenario
            console.error('Error loading more data:', error);
            throw error; // Propagate the error to the caller
        } finally {
            // Restore previous scroll position
            if (container) {
                container.scrollTop = currentScrollPosition;
            }
        }
    },
    handleImageError(event) {
      event.target.style.display = 'none'; // Hide the image
    },
    topGetAllAttachment () {
    this.topAttachmentsList = []
    this.topTotalAttachmentCount = 0
      MyJobApp.GetAllAttachment(
        0,
        100,
        response => {
          console.log('response--',response)
          if (response.Data.attachmentList !== null) {
            let attach = []
            response.Data.attachmentList.forEach(a => {
                  let temp = a.fileType.split('/')
                  let type = ''
                  if (temp[0] === 'image' ) {
                      type = 'image'
                  } else if (temp[0] === 'application' && temp[1] === 'pdf') {
                    type = 'pdf'
                  } else if (temp[0] === 'video' ) {
                    type = 'video'
                  }
                  attach.push({...a, docType: type})
              })
            this.topAttachmentsList = attach
          } else {
            this.topAttachmentsList = []
          }
          this.pathPrefix = response.Data.pathPrefix
          this.topTotalAttachmentCount = response.Data.count
          setTimeout(() => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }, 1000);
        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    otherGetAllAttachment() {
    MyJobApp.GetAllAttachment(
      this.otherStart,
      this.otherLength,
      response => {
        console.log('response--', response)
        if (response.Data.attachmentList !== null) {
          let attach = []
          response.Data.attachmentList.forEach(a => {
            let temp = a.fileType.split('/')
            let type = ''
            if (temp[0] === 'image') {
              type = 'image'
            } else if (temp[0] === 'application' && temp[1] === 'pdf') {
              type = 'pdf'
            } else if (temp[0] === 'video') {
              type = 'video'
            }
            attach.push({ ...a, docType: type })
          })

          // Check if otherAttachmentsList already has items
          if (this.otherAttachmentsList.length > 0) {
            // Append new data to existing array
            this.otherAttachmentsList = [...this.otherAttachmentsList, ...attach]
          } else {
            // Set initial data if otherAttachmentsList is empty
            this.otherAttachmentsList = attach
          }
        } else {
          // Handle case where attachmentList is null
          this.otherAttachmentsList = []
        }
        
        // Update otherTotalAttachmentCount and pathPrefix
        this.pathPrefix = response.Data.pathPrefix
        this.otherTotalAttachmentCount = response.Data.count
        setTimeout(() => {
          this.$store.dispatch('SetLoader', { status: false, message: '' })
        }, 1000);
      },
      () => {
        this.$store.dispatch('SetLoader', { status: false, message: '' });
      }
    );
   },
   showFullImage (data) {
        this.allAttachmentsArray = []
        this.$store.dispatch('SetLoader', {status: true, message: 'Please Wait...'})
        if (this.topAttachmentsList.length > 0 ) {
          this.topAttachmentsList.forEach(element => {
            this.allAttachmentsArray.push(element)
          })
        }
        if (this.otherAttachmentsList.length > 0 ) {
          this.otherAttachmentsList.forEach(element => {
            this.allAttachmentsArray.push(element)
          })
        }
        setTimeout(() => {
          this.selectedData = data
          this.shoFullImage = true
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }, 1000)
    },
    getManageStorageOverviewDetail () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetManageStorageOverviewDetail(
          response => {
            console.log('response--',response)
            this.storageOverViewDetail = response.Data !== null ? response.Data : {}
            setTimeout(() => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }, 1000);
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  },
  beforeDestroy () {
    this.$root.$off("fullImageHandler");
  }
}
</script>
<style scoped>
.lead_name_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.attachment-card {
  display: flex;
  align-items: center;
  position: relative;
}
.scroll-img-view{
  height: 104px;
}
.zIndex {
  z-index: 100 !important;
}
</style>